<template>
  <div>
    <v-col class="mt-3">
      <v-row align="center">
        <span v-for="step in steps.slice(0, steps.length - 1)" :key="step.id">
          <router-link
            :to="step.path"
            style="text-decoration: none; color: black"
            >{{ step.title | localize }}</router-link
          ><v-icon color="#CACACA">mdi-chevron-right</v-icon>
        </span>
        <span
          v-for="step in steps.slice(steps.length - 1, steps.length)"
          :key="step.id"
          class="font-weight-bold"
        >
          {{ step.title }}
        </span>
      </v-row>
    </v-col>
  </div>
</template>

<script>
export default {
  props: {
    name: {
      require: false,
    },
  },
  mounted() {
    if(this.$route.name == 'detail_info'){
      this.steps[0].path = '/profile'
      this.steps[0].title = "header_my_account"
    }
    this.steps[1].title = this.name;
  },
  watch: {
    name: {
      handler() {
        this.steps[1].title = this.name;
      }
    }
  },
  data: () => ({
    steps: [
      {
        id: 1,
        title: "header_our_attractions_part1",
        path: "/attractions",
      },
      {
        id: 2,
        title: "Club Beisbol Charros de Jalisco",
        path: "/attraction",
      },
    ],
  }),
};
</script>

<style>
</style>
<template>
  <v-main>
    <v-container>
      <page-steps
        :name="
          userLocale == 'es'
            ? citypass.title_es 
            : citypass.title_en
        "
      />
      <user-profile-detail-info-page :citypass="citypass" :key="key" />
    </v-container>
  </v-main>
</template>

<script>
import userProfileDetailInfoPage from "../components/userProfile/userProfileDetailInfoPage.vue";
import PageSteps from "../components/UI/PageSteps.vue";
import CitypassService from "../requests/Citypass/CitypassService";
import { mapGetters } from "vuex";
export default {
  components: {
    userProfileDetailInfoPage,
    PageSteps,
  },
  data: () => ({
    citypass: {},
    userLocale: "",
    key: 0,
  }),
  mounted() {
    this.getCitypass();
    this.userLocale = this.loggedUser
      ? this.loggedUser.locale
      : this.user.locale;
  },
  methods: {
    async getCitypass() {
      let response = await CitypassService.getCitypass(
        this.$route.params.citypass_uid
      );
      this.citypass = response.result;
    },
  },
  computed: {
    ...mapGetters(["loggedUser"]),
    ...mapGetters(["user"]),
  },
  watch: {
    loggedUser: {
      deep: true,
      handler() {
        this.userLocale = this.loggedUser
          ? this.loggedUser.locale
          : this.user.locale;
        this.key++;
      },
    },
    user: {
      deep: true,
      handler() {
        this.userLocale = this.loggedUser
          ? this.loggedUser.locale
          : this.user.locale;
        this.key++;
      },
    },
  },
};
</script>

<style>
</style>
<template>
  <v-col>
    <div v-if="showLoader">
      <loader />
    </div>
    <div v-else>
      <v-row
        align="center"
        :justify="$vuetify.breakpoint.smAndDown ? 'center' : 'start'"
        class="mb-1 pl-0"
      >
        <!-- <h2 style="font-weight: 600; font-size: 32px">
          {{
            userLocale == "es"
              ? citypass.title_es
              : citypass.title_en
          }}
        </h2> -->
        <v-radio-group
          v-model="radio"
          row
          :class="$vuetify.breakpoint.smAndDown ? 'mt-1 pt-0' : 'ml-0'"
          label=""
          hide-details
        >
          <v-row no-gutters justify="center" class="px-0 py-0 mt-1">
            <v-radio
              :label="'profile_details_radio_button_all' | localize"
              value="all"
              class="textStyle" />
            <v-radio
              :label="'profile_details_radio_button_included' | localize"
              value="included"
              class="ml-5 mr-5 textStyle" />
            <v-radio
              :label="'profile_details_radio_button_discounted' | localize"
              value="discounted"
              class="textStyle"
          /></v-row>
        </v-radio-group>
        <!-- <v-divider class="ml-10" v-if="!$vuetify.breakpoint.xs" /> -->
      </v-row>
      <div v-if="radio == 'all' || radio == 'included'">
        <v-row
          align="center"
          justify="center"
          class="mt-5"
          style="text-align: center"
        >
          <span class="ml-0 mr-5 blackTitle">
            <span style="color: #e62076">
              {{ includesAttraction.length }}
              {{ "profile_details_radio_button_included" | localize }}
            </span>
            {{ "profile_details_heading_visiting_Guadalajara" | localize }}
          </span>
          <v-divider
            style="border: 1px solid #e62076"
            v-if="!$vuetify.breakpoint.smAndDown"
            class="mr-5"
          />
          <v-btn
            color="#6ABAA4"
            style="text-transform: unset !important; font-size: 16px"
            class="white--text rounded-lg"
            width="260px"
            height="40px"
            @click="showQRDialog = true"
          >
            <v-icon class="mr-1">mdi-qrcode</v-icon
            >{{ "profile_details_button_qr_code_ticket" | localize }}
          </v-btn>
        </v-row>
        <active-attraction-list
          :isDiscount="false"
          :attractions="includesAttraction"
        />
      </div>
      <div v-if="radio == 'all' || radio == 'discounted'">
        <v-row align="center" class="mt-5" style="text-align: center">
          <span class="ml-5 mr-5 blackTitle">
            <span style="color: #e62076">
              {{ discountAttraction.length }}
              {{ "profile_details_radio_button_discounted" | localize }}
            </span>
            {{ "profile_details_heading_visiting_Guadalajara" | localize }}
          </span>
          <v-divider style="border: 1px solid #e62076" class="mr-5" />
        </v-row>
        <active-attraction-list
          :isDiscount="true"
          :attractions="discountAttraction"
        />
      </div>
    </div>
    <QRDialog
      v-if="showQRDialog"
      :visible="showQRDialog"
      :qr_code="qr_code"
      @close="showQRDialog = false"
    />
  </v-col>
</template>

<script>
import activeAttractionList from "./activeAttractionList.vue";
import CartService from "@/requests/Cart/CartService";
import AttractionsService from "../../requests/Attractions/AttractionsService";
import Loader from "@/components/UI/Loader";
import { mapGetters } from "vuex";
import QRDialog from "../UI/QRDialog.vue";

export default {
  components: {
    activeAttractionList,
    Loader,
    QRDialog,
  },
  data: () => ({
    radio: "all",
    orderDetail: [],
    orderDetailItemsAttractionUid: [],
    orderDetailItemsQRCode: [],
    orderDetailBookingItems: [],
    includesAttraction: [],
    discountAttraction: [],
    attractionsList: [],
    userLocale: null,
    showLoader: true,
    isAdditionalAttractionUuid: "",
    showQRDialog: false,
    qr_code: {},
  }),
  props: {
    citypass: {
      require: true,
    },
  },
  mounted() {
    this.getDetailOrder();
    this.userLocale = this.loggedUser
      ? this.loggedUser.locale
      : this.user.locale;
  },
  computed: {
    ...mapGetters(["loggedUser"]),
    ...mapGetters(["user"]),
  },
  methods: {
    async getDetailOrder() {
      let response = await CartService.getDetailOrder(
        this.$route.params.order_uid,
        this.$route.params.parent_uuid,
      );
      this.orderDetail = response.result;
      // this.isAdditionalAttractionUuid =
      //   response.result.items[0].citypass.attractions.filter(
      //     (item) => item.is_additional == "1"
      //   )[0].uuid;
      this.qr_code.qr_code = this.orderDetail.items[0].qr_code;
      this.orderDetail.items.forEach((item) => {
        this.orderDetailItemsAttractionUid.push(item.attraction_uuid);
        this.orderDetailItemsQRCode.push({
          attraction_uuid: item.attraction_uuid,
          qr_code: item.qr_code,
        });
      });
      this.orderDetail.itemsList = this.orderDetail.items;
      this.orderDetail.items = [...new Set(this.orderDetailItemsAttractionUid)];
      // if (this.isAdditionalAttractionUuid !== "") {
      //   this.orderDetail.items = this.orderDetail.items.filter(
      //     (item) => item !== this.isAdditionalAttractionUuid
      //   );
      // }
      this.getAttraction();
    },
    async getAttraction() {
      let temporaryArray = []; //Array for temporary data
      //Get attractions includes in citypass
      let included_attraction = await AttractionsService.getAttractionsByUids(
        String(this.orderDetail.items)
      );
      //CHECK VALID RESPONSE
      if (included_attraction.result !== null) {
        included_attraction.result.forEach((attraction, index) => {
          let attractionEvent = this.orderDetail.itemsList.filter(
            (attraction_item) =>
              attraction_item.attraction_uuid == attraction.uid &&
              attraction_item.event_uuid !== null
          )?.[0];
          this.includesAttraction.push({
            id: attraction.id,
            uid: attraction.uid,
            title: attraction.title,
            geo_lat: attraction.geo_lat,
            geo_lon: attraction.geo_lon,
            images: attraction.images,
            address: attraction.address,
            closest_stop: attraction.closest_stop,
            working_hours: attraction.working_hours,
            short_description: attraction.short_description,
            qr_code: this.orderDetailItemsQRCode[index],
            status: attraction.status,
            event_ended_at: attractionEvent
              ? attractionEvent.event_ended_at
              : "",
            event_started_at: attractionEvent
              ? attractionEvent.event_started_at
              : "",
          });
        });
        console.log(this.includesAttraction)
      }

      // for (let i = 0; i < this.orderDetail.items.length; i++) {
      //   //Request for get attraction
      //   let response = await AttractionsService.getAttraction(
      //     this.orderDetail.items[i]
      //   );
      //   //Check valid response result
      //   if (response.result !== null) {
      //     //Added includes attraction in array for output for user
      //     this.includesAttraction.push({
      //       id: response.result.id,
      //       uid: response.result.uid,
      //       title: response.result.title,
      //       geo_lat: response.result.geo_lat,
      //       geo_lon: response.result.geo_lon,
      //       images: response.result.images,
      //       address: response.result.address,
      //       closest_stop: response.result.closest_stop,
      //       working_hours: response.result.working_hours,
      //       short_description: response.result.short_description,
      //       qr_code: this.orderDetailItemsQRCode[i],
      //       status: response.result.status,
      //     });
      //   }
      // }
      //Filter working hours attraction
      await this.filterAttractions(this.includesAttraction);
      //Add all uuids in temporary array for filter discount attraction
      temporaryArray = this.citypass.attractions.filter(
        (item) => !this.orderDetail.items.includes(item)
      );
      //Filter free attractions
      this.includesAttraction.forEach((attraction) => {
        temporaryArray = temporaryArray.filter(
          (item) => item.uuid !== attraction.uid
        );
      });
      let uids = [];
      temporaryArray.forEach((attraction) => uids.push(attraction.uuid));
      if (uids.length) {
      let discount_attraction = await AttractionsService.getAttractionsByUids(
        String(uids)
      );
      //Check if valid response result
      if (discount_attraction.result !== null) {
        discount_attraction.result.forEach((attraction) => {
          if (attraction.status == "Active") {
            this.discountAttraction.push(attraction);
          }
        });
      }
      // for (let i = 0; i < temporaryArray.length; i++) {
      //   //Check additional attraction
      //   if (temporaryArray[i].is_additional == "0") {
      //     let response = await AttractionsService.getAttraction(
      //       temporaryArray[i]
      //     );
      //     //Check if valid response result
      //     if (response.result !== null) {
      //       //Check status attraction
      //       if (response.result.status == "Active") {
      //         //Added discount attraction in array
      //         this.discountAttraction.push(response.result);
      //       }
      //     }
      //   }
      // }
      await this.filterAttractions(this.discountAttraction);
      }
      this.showLoader = false;
    },
    filterAttractions(attractions) {
      this.attractionsList = attractions;
      let filteredWorkingHours = [];
      for (let i = 0; i < this.attractionsList.length; i++) {
        if (typeof this.attractionsList[i].working_hours == "object") {
          filteredWorkingHours.push(this.attractionsList[i]);
        }
      }
      this.attractionsList = filteredWorkingHours;
      for (let index = 0; index < this.attractionsList.length; index++) {
        this.working_hoursValues = Object.values(
          this.attractionsList[index].working_hours
        );
        let working_hoursKey = Object.keys(
          this.attractionsList[index].working_hours
        );
        let working_hours = [];
        for (let i = 0; i < this.working_hoursValues.length; i++) {
          working_hours.push({
            day: working_hoursKey[i],
            from: this.working_hoursValues[i].from,
            to: this.working_hoursValues[i].to,
          });
        }
        var helper = {};
        var result = working_hours.reduce(function (groupHours, hours) {
          var key = hours.to + "-" + hours.from;
          if (!helper[key]) {
            helper[key] = Object.assign({}, hours); // create a copy of hours
            groupHours.push(helper[key]);
          } else {
            helper[key].day += "-" + hours.day;
          }

          return groupHours;
        }, []);
        this.attractionsList[index].working_hours = [...result];
      }
    },
  },
  watch: {
    "loggedUser.locale": {
      deep: true,
      handler() {
        this.userLocale = this.loggedUser
          ? this.loggedUser.locale
          : this.user.locale;
      },
    },
    "user.locale": {
      deep: true,
      handler() {
        this.userLocale = this.loggedUser
          ? this.loggedUser.locale
          : this.user.locale;
      },
      radio: {
        deep: true,
        handler() {
          console.log("radio", this.radio);
        },
      },
    },
  },
};
</script>

<style>
.textStyle {
  font-weight: 700;
  font-size: 16px;
  color: #212121;
}
.dividerStyle {
  border: 1px solid #e62076;
}
.blackTitle {
  font-weight: 800;
  font-size: 24px;
  color: black;
}
</style>

<template>
  <div class="mt-10 ml-0 ml-sm-4">
    <div v-for="attraction in attractions" :key="attraction.key" class="mb-5">
      <v-row
        justify="center"
        :style="
          $vuetify.breakpoint.xs
            ? 'display: block !important; text-align:start;'
            : ''
        "
      >
        <v-col
          cols="12"
          lg="3"
          md="3"
          xl="3"
          sm="12"
          xs="12"
          :class="
            $vuetify.breakpoint.xs
              ? 'justify-content-center text-center d-block'
              : ''
          "
        >
          <div
            v-if="attraction.images.length > 0"
            style="width: 221px !important"
            class="mx-auto mx-sm-0"
          >
            <div v-for="(image, i) in attraction.images" :key="i">
              <router-link :to="'/attractions/' + attraction.uid">
                <v-img
                  style="
                    border-radius: 10%;
                    object-fit: cover;
                    height: 221px !important;
                  "
                  width="221px"
                  v-if="i == 0"
                  :src="image.thumbs[2]"
                  alt="no img"
                />
              </router-link>
            </div>
          </div>
          <div v-else style="width: 221px !important" class="mx-auto mx-sm-0">
            <router-link :to="'/attractions/' + attraction.uid">
              <v-img
                style="
                  border-radius: 10%;
                  object-fit: cover;
                  height: 221px !important;
                "
                width="221px"
                src="@/assets/IoP.png"
                alt="no img"
              />
            </router-link>
          </div>
          <!-- <v-btn
            v-if="!isDiscount"
            color="#6ABAA4"
            style="text-transform: unset !important; font-size: 16px"
            class="white--text mt-2 rounded-lg"
            width="220px"
            height="44px"
            @click="(qr_code = attraction.qr_code), (showQRDialog = true)"
          >
            {{ "profile_details_button_qr_code_ticket" | localize }}
          </v-btn> -->
        </v-col>
        <v-col
          cols="12"
          lg="8"
          md="8"
          xl="8"
          sm="8"
          :class="$vuetify.breakpoint.xs ? 'px-5' : ''"
        >
          <h2>
            <router-link
              :to="'/attractions/' + attraction.uid"
              style="text-decoration: none; color: #e62076"
            >
              {{ attraction.title }}
            </router-link>
          </h2>
          <v-row align="center" class="mt-1">
            <img
              src="@/assets/Vector.svg"
              class="ml-3"
              style="align-self: start; margin-top: 3px"
            />
            <v-col
              cols="10"
              lg="11"
              md="10"
              xl="11"
              sm="11"
              class="px-0 py-0 ml-2"
            >
              <span class="font-weight-bold"
                ><a
                  :href="`https://www.google.com/maps/search/?api=1&query=${attraction.geo_lat},${attraction.geo_lon}`"
                  target="_blank"
                  style="text-decoration: none; color: black"
                  ><u>{{ attraction.address }}</u></a
                >
              </span>
            </v-col>
          </v-row>
          <br />
          <v-row class="py-2">
            <img
              src="@/assets/Buses.svg"
              class="ml-3"
              v-if="attraction.closest_stop && attraction.closest_stop !== ''"
            />
            <v-col
              v-if="attraction.closest_stop && attraction.closest_stop !== ''"
              cols="10"
              lg="11"
              md="10"
              xl="11"
              sm="11"
              class="px-0 py-0 ml-2"
            >
              <span class="font-weight-bold">
                {{ attraction.closest_stop }}
              </span>
            </v-col>
          </v-row>
          <v-row
            :align="$vuetify.breakpoint.xs ? 'start' : 'center'"
            class="mb-1"
          >
            <img
              src="@/assets/Time.svg"
              class="ml-3"
              :class="$vuetify.breakpoint.xs ? 'pt-1' : ''"
            />
            <v-col
              cols="10"
              lg="11"
              md="10"
              xl="11"
              sm="11"
              class="px-0 py-0 ml-2"
            >
              <div v-if="!$vuetify.breakpoint.xs">
                <!-- Hours list for other screen -->

                <span
                  class="font-weight-bold"
                  v-for="(key, index) in attraction.working_hours"
                  :key="index"
                >
                  <span v-if="key.day.length > 3">
                    {{ key.day.substr(0, 3) | localize }}-{{
                      key.day.substr(key.day.length - 3, key.day.length)
                        | localize
                    }}:&nbsp;{{ key.from + "-" + key.to
                    }}<span v-if="index != attraction.working_hours.length - 1"
                      >,
                    </span>
                  </span>
                  <span v-else>
                    {{ key.day.substr(0, 3) | localize }}&nbsp;{{
                      key.from + "-" + key.to
                    }}<span v-if="index != attraction.working_hours.length - 1"
                      >,
                    </span>
                  </span>
                </span>
              </div>
              <!-- /Hours list for other screen -->
              <!-- Hours list for mobile screen  -->
              <v-row no-gutters align="start" v-else>
                <span class="font-weight-bold"> </span>
                <v-col cols="11">
                  <span
                    class="font-weight-bold"
                    v-for="(key, index) in attraction.working_hours"
                    :key="index"
                  >
                    <span v-if="key.day.length > 3">
                      {{ key.day.substr(0, 3) | localize }}-{{
                        key.day.substr(key.day.length - 3, key.day.length)
                          | localize
                      }}:&nbsp;{{ key.from + "-" + key.to
                      }}<span
                        v-if="index != attraction.working_hours.length - 1"
                        >,
                      </span>
                      <br />
                    </span>
                    <span v-else>
                      {{ key.day.substr(0, 3) | localize }}:&nbsp;{{
                        key.from + "-" + key.to
                      }}<span
                        v-if="index != attraction.working_hours.length - 1"
                        >,
                      </span>
                      <br />
                    </span>
                  </span>
                </v-col>
              </v-row>

              <!-- /Hours list for mobile screen  -->
            </v-col>
          </v-row>
          <p class="black--text" style="margin-top: 12px">
            {{ attraction.short_description }}
          </p>
          <v-row no-gutters align="center" v-if="attraction.event_started_at">
            <button class="choosedDate">
              {{
                new Date(attraction.event_started_at).toLocaleTimeString(
                  "en-GB",
                  {
                    hour: "2-digit",
                    minute: "2-digit",
                  }
                )
              }}
              -
              {{
                new Date(attraction.event_ended_at).toLocaleTimeString(
                  "en-GB",
                  {
                    hour: "2-digit",
                    minute: "2-digit",
                  }
                )
              }}
              {{
                new Date(attraction.event_ended_at).toLocaleDateString(
                  loggedUser
                    ? loggedUser.locale == "en"
                      ? "en-GB"
                      : "es-ES"
                    : user.locale == "en"
                    ? "en-GB"
                    : "es-ES",
                  {
                    weekday: "long",
                    month: "long",
                    day: "numeric",
                    year: "numeric",
                    timeZone: 'Europe/London',
                  }
                )
              }}
            </button>
            <p
              style="margin-bottom: 0px; margin-top: 15px; margin-left: 24px"
              class="black--text"
            >
              {{ "my_account_slot_notification" | localize }}
            </p>
          </v-row>
        </v-col>
      </v-row>
    </div>
    <!-- <QRDialog
      v-if="showQRDialog"
      :visible="showQRDialog"
      :qr_code="qr_code"
      @close="showQRDialog = false"
    /> -->
  </div>
</template>

<script>
// import QRDialog from "../UI/QRDialog.vue";
import { mapGetters } from "vuex";
export default {
  // components: {
  //   QRDialog,
  // },
  mounted() {
    window.scrollTo(0, 0);
  },
  data: () => ({
    showQRDialog: false,
    qr_code: "",
  }),
  props: {
    isDiscount: {
      require: true,
    },
    attractions: {
      type: Array,
      require: true,
    },
  },
  computed: {
    ...mapGetters(["user"]),
    ...mapGetters(["loggedUser"]),
  },
};
</script>

<style>
.choosedDate {
  display: flex;
  height: 32px;
  padding: 0px 12px 0px 12px;
  justify-content: center;
  align-items: center;
  border-radius: 20px;
  background: rgba(0, 0, 0, 0.12);
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 130%;
  letter-spacing: -0.266px;
  color: #212121;
  margin-top: 14px;
}
</style>

<template>
  <v-dialog width="auto" v-model="visibility">
    <div v-if="qr_code">
      <Qrcode class="d-flex justify-center" :text="qr_code.qr_code"> </Qrcode>
    </div>
    <div v-else>
      <v-card height="300px" width="300px" color="white" class="black--text">
          <span style="min-height: 45vh" class="d-flex align-center ml-10"
          >The QR code is temporarily unavailable</span
        >
      </v-card>
    </div>
  </v-dialog>
</template>

<script>
import Qrcode from "vue-qr-generator";

export default {
  components: {
    Qrcode,
  },
  props: {
    qr_code: {
      require: true,
    },
    visible: {
      type: Boolean,
      require: true,
    },
  },
  computed: {
    visibility: {
      get() {
        return this.visible;
      },
      set() {
        this.$emit("close");
      },
    },
  },
};
</script>

<style lang="scss" scoped>
@media print {
  body {
    margin: 0;
    color: #000;
    background-color: rgb(131, 131, 131);
  }
}
</style>